import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import Store from "../index";
import {
    AttendeeSearchResult,
    AttendeeElasticSearchResult,
    AttendeeSearchInput,
    AttendeeSearchDropdownField
} from "@/types/interfaces";
import { getApiClient } from "@/services/api";

// TODO this endpoint needs to be renamed to `search-attendees`
// `search` is too generic.
// This module is only for functionality related to the following endpoint:
const endpoint = "search";

@Module({
    dynamic: true,
    store: Store,
    name: "AttendeeSearchModule",
    namespaced: true
})
export default class AttendeeSearchModule extends VuexModule {
    attendeeSearchResults: Array<AttendeeSearchResult> = [];
    attendeeList: Array<AttendeeSearchResult> = [];
    currentCompanyResults: Array<AttendeeSearchResult> = [];
    dropdownFields: Array<AttendeeSearchDropdownField> = [];
    isAttendeeListLoaded = false;
    isCompanyDataLoaded = false;

    @Mutation
    public setAttendeeSearchResults(data: Array<AttendeeSearchResult>) {
        this.attendeeSearchResults = data;
    }

    @Mutation
    public setCurrentCompanyResults(data: Array<AttendeeSearchResult>) {
        this.isCompanyDataLoaded = true;
        this.currentCompanyResults = data;
    }

    @Mutation
    public setAttendeeList(data: Array<AttendeeSearchResult>) {
        this.isAttendeeListLoaded = true;
        this.attendeeList = data;
    }

    @Mutation
    public setDropdownFields(data: Array<AttendeeSearchDropdownField>) {
        this.dropdownFields = data;
    }

    @Action({ commit: "setAttendeeSearchResults" })
    async searchAttendees(formFields: AttendeeSearchInput) {
        return new Promise((resolve, reject) => {
            const token = this.context.rootGetters.idToken;
            getApiClient()
                .post(`/${endpoint}`, formFields, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    let returnValue = [];

                    if (response.data && response.data.hits) {
                        const hits = response.data.hits;
                        returnValue = hits
                            .map((item: AttendeeElasticSearchResult) => {
                                const returnValue = Vue.prototype.MgFormatElasticSearchResult(
                                    item._source
                                );
                                return returnValue;
                            })
                            .sort(
                                (a: { lastName: "" }, b: { lastName: "" }) => {
                                    return a.lastName.localeCompare(b.lastName);
                                }
                            );
                    }

                    return resolve(returnValue);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setCurrentCompanyResults" })
    async getCompanyResults(id: string) {
        return new Promise((resolve, reject) => {
            const token = this.context.rootGetters.idToken;
            getApiClient()
                .post(
                    `/${endpoint}`,
                    { exactCompanyId: id },
                    {
                        headers: {
                            Authorization: `bearer ${token}`
                        }
                    }
                )
                .then((response) => {
                    let returnValue = [];

                    if (response.data && response.data.hits) {
                        const hits = response.data.hits;
                        returnValue = hits
                            .map((item: AttendeeElasticSearchResult) => {
                                const returnValue = Vue.prototype.MgFormatElasticSearchResult(
                                    item._source
                                );
                                return returnValue;
                            })
                            .sort(
                                (a: { lastName: "" }, b: { lastName: "" }) => {
                                    return a.lastName.localeCompare(b.lastName);
                                }
                            );
                    }

                    return resolve(returnValue);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setAttendeeList" })
    async getAttendeeList() {
        return new Promise((resolve, reject) => {
            const token = this.context.rootGetters.idToken;

            const fields =
                this.context.rootGetters.getPageOptions("attendees")
                    ?.attendeeListFields &&
                Array.isArray(
                    this.context.rootGetters.getPageOptions("attendees")
                        .attendeeListFields
                )
                    ? this.context.rootGetters.getPageOptions("attendees")
                          .attendeeListFields
                    : ["firstName", "lastName", "companyName"];

            getApiClient()
                .post(
                    `/allAttendees`,
                    {
                        fields: fields
                    },
                    {
                        headers: {
                            Authorization: `bearer ${token}`
                        }
                    }
                )
                .then((response) => {
                    let returnValue = [];

                    if (response && response.data) {
                        returnValue = response.data;
                    }

                    return resolve(returnValue);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setDropdownFields" })
    async getDropdownFields() {
        return new Promise((resolve, reject) => {
            const token = this.context.rootGetters.idToken;

            const fields =
                this.context.rootGetters.getPageOptions("attendees")
                    ?.searchDropdownFields &&
                Array.isArray(
                    this.context.rootGetters.getPageOptions("attendees")
                        .searchDropdownFields
                )
                    ? this.context.rootGetters.getPageOptions("attendees")
                          .searchDropdownFields
                    : [];

            if (fields.length) {
                getApiClient()
                    .post(
                        `/${endpoint}`,
                        {
                            dropdownFields: fields
                        },
                        {
                            headers: {
                                Authorization: `bearer ${token}`
                            }
                        }
                    )
                    .then((response) => {
                        const returnValue: Array<AttendeeSearchDropdownField> = [];

                        if (response && Array.isArray(response.data)) {
                            const items = response.data;

                            items.forEach((item: AttendeeSearchDropdownField) =>
                                returnValue.push(item)
                            );
                        }

                        return resolve(returnValue);
                    })
                    .catch((error) => {
                        return reject(error);
                    });
            }
        });
    }
}
