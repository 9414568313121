





































































import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import attendeeSearchVuexModule from "@/store/vuex-modules/searchAttendeeData";
import Spinners from "@/components/utilities/Spinners.vue";
import { AttendeeSearchFormFields } from "@/types/interfaces";

const attendeeSearchStore = getModule(attendeeSearchVuexModule);

interface SearchFieldOutput {
    name: string;
    isDropdown: boolean;
    items: Array<string>;
}

@Component({
    components: {
        Spinners
    }
})
export default class AttendeeSearchForm extends Vue {
    @Prop({ default: "" })
    profileOwnerId!: string;

    isLoading = false;
    formFields: AttendeeSearchFormFields = {
        firstName: "",
        lastName: "",
        companyName: "",
        country: "",
        city: "",
        state: "",
        title: "",
        specialInterests: "",
        system: ""
    };

    mounted() {
        attendeeSearchStore.getDropdownFields();
    }

    get pageOptions() {
        return this.$store.getters.getPageOptions("attendees");
    }

    get searchFields() {
        return Array.isArray(this.pageOptions.searchFields)
            ? this.pageOptions.searchFields
            : ["firstName", "lastName", "companyName", "country"];
    }

    get searchDropdownFields() {
        return Array.isArray(this.pageOptions.searchDropdownFields)
            ? this.pageOptions.searchDropdownFields
            : [];
    }

    get validSearchFields() {
        return this.searchFields
            .map((field: string) => {
                const isDropdown = this.searchDropdownFields.includes(field);
                const dropdown = this.getDropdownField(field);
                const dropdownItems = dropdown ? dropdown.items : [];

                return {
                    name: field,
                    isDropdown: isDropdown,
                    items: dropdownItems
                };
            })
            .filter((field: SearchFieldOutput) => {
                return (
                    !field.isDropdown ||
                    (field.isDropdown && field.items.length)
                );
            });
    }

    get formLabels() {
        return {
            firstName: this.pageOptions.fieldLabels?.firstName
                ? this.pageOptions.fieldLabels.firstName
                : "First Name",
            lastName: this.pageOptions.fieldLabels?.lastName
                ? this.pageOptions.fieldLabels.lastName
                : "Last Name",
            companyName: this.pageOptions.fieldLabels?.companyName
                ? this.pageOptions.fieldLabels.companyName
                : "Organization",
            country: this.pageOptions.fieldLabels?.country
                ? this.pageOptions.fieldLabels.country
                : "Country",
            state: this.pageOptions.fieldLabels?.state
                ? this.pageOptions.fieldLabels.state
                : "State",
            city: this.pageOptions.fieldLabels?.city
                ? this.pageOptions.fieldLabels.city
                : "City",
            title: this.pageOptions.fieldLabels?.title
                ? this.pageOptions.fieldLabels.title
                : "Title",
            specialInterests: this.pageOptions.fieldLabels?.specialInterests
                ? this.pageOptions.fieldLabels.specialInterests
                : "Special Interest",
            system: this.pageOptions.fieldLabels?.system
                ? this.pageOptions.fieldLabels.system
                : "System"
        };
    }

    get dropdownFields() {
        return attendeeSearchStore.dropdownFields;
    }

    get hasAnySearchFields() {
        let hasFields = false;

        Object.keys(this.formFields).forEach((keyName) => {
            const key = keyName as keyof AttendeeSearchFormFields;
            if (this.formFields[key] != "") {
                hasFields = true;
            }
        });

        return hasFields;
    }

    get fieldClass() {
        return this.pageOptions.searchFieldClass
            ? this.pageOptions.searchFieldClass
            : "flex-item--half";
    }

    getDropdownField(fieldName: string) {
        const potentialField = this.dropdownFields.find(
            (field) => field.name === fieldName
        );

        return potentialField ? potentialField : false;
    }

    submitForm() {
        if (!this.hasAnySearchFields) {
            return;
        }

        this.isLoading = true;

        const searchInput = {
            matchFirstName: this.formFields.firstName,
            matchLastName: this.formFields.lastName,
            matchCompanyName: this.formFields.companyName,
            matchCountry: this.formFields.country,
            matchCity: this.formFields.city,
            matchState: this.formFields.state,
            matchTitle: this.formFields.title,
            matchSpecialInterest: this.formFields.specialInterests,
            matchSystem: this.formFields.system
        };

        attendeeSearchStore
            .searchAttendees(searchInput)
            .then(() => {
                this.$router.push("/attendees/results");
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    clearForm() {
        Object.keys(this.formFields).forEach((keyName) => {
            const key = keyName as keyof AttendeeSearchFormFields;
            this.formFields[key] = "";
        });
    }
}
