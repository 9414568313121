import Vue from "vue";
import axios from "axios";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import { AttendeeDataObject, GetAttendeeDataOptions } from "@/types/interfaces";
import Store from "../index";

// This module is only for functionality related to the following endpoint:
const endpoint = "attendees";

@Module({
    dynamic: true,
    store: Store,
    name: "AttendeesModule",
    namespaced: true
})
export default class AttendeesModule extends VuexModule {
    attendee: AttendeeDataObject = {};
    attendeeUpdatePayload: AttendeeDataObject = {};
    avatarImage: File | string = "";
    avatarFileName = "";
    attendeeAvatarUrl = "";
    validFieldsExtraFields = [
        "myAvailability",
        "biography",
        "aboutOrganization"
    ];

    get bioExtraFields() {
        const pageOptions = this.context.rootGetters.getPageOptions(
            "attendees"
        );
        const profileExtraFields = pageOptions.profileExtraFields || [];

        return profileExtraFields.filter((item: string) =>
            this.validFieldsExtraFields.includes(item)
        );
    }

    @Mutation
    public setAttendeeAvatarImage(data: File | string) {
        this.avatarImage = data;
    }

    @Mutation
    public setAttendeeAvatarFileName(data: string) {
        this.avatarFileName = data;
    }

    @Mutation
    public setAttendeeAvatarUrl(data: string) {
        this.attendeeAvatarUrl = data;
    }

    @Mutation
    public setAttendee(data: AttendeeDataObject) {
        this.attendee = data;
    }

    @Mutation
    public setAttendeeUpdatePayload(data: AttendeeDataObject) {
        this.attendeeUpdatePayload = data;
    }

    @Action({ commit: "setAttendeeAvatarUrl", rawError: true })
    async getAttendeeAvatarUrl(options: GetAttendeeDataOptions) {
        const attendeeId = options.id;
        const token = this.context.rootGetters.idToken;

        try {
            const image = await getApiClient().get(
                `${endpoint}/${attendeeId}/avatar`,
                {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            );

            return image.data;
        } catch (error) {
            throw new Error("Could not get Avatar url.");
        }
    }

    @Action({ commit: "setAttendee", rawError: true })
    getAttendee(options: GetAttendeeDataOptions) {
        const attendeeId = options.id;
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`${endpoint}/${attendeeId}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    getAttendees(ids: Array<string> = []) {
        const token = this.context.rootGetters.idToken;

        const idString = ids.join(",");

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(endpoint, {
                    headers: {
                        Authorization: `bearer ${token}`
                    },
                    params: {
                        ids: idString
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    getAttendeeObject(id: string) {
        const attendeeId = id;
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`${endpoint}/${attendeeId}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setAttendee", rawError: true })
    async putAttendee(options: GetAttendeeDataOptions) {
        const attendeeId = options.id;
        const token = this.context.rootGetters.idToken;
        const payload = this.attendeeUpdatePayload;

        this.validFieldsExtraFields.forEach((element) => {
            const x = element as keyof AttendeeDataObject;
            if (element in payload) {
                payload[x] = Vue.prototype.MgSanitize(payload[x]);
            }
        });

        return new Promise((resolve, reject) => {
            getApiClient()
                .put(`${endpoint}/${attendeeId}`, payload, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setAttendeeUpdatePayload({});
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    @Action({ rawError: true })
    async uploadAttendeeAvatar(options: GetAttendeeDataOptions) {
        const attendeeId = options.id;
        const token = this.context.rootGetters.idToken;

        let putUrlResponse = { data: "" };
        let fileType = "";

        if (options.image instanceof File) {
            fileType = options.image.type;
        }

        if (!fileType) {
            throw new Error("Image has no file type specified.");
        }

        try {
            putUrlResponse = await getApiClient().get(
                `${endpoint}/${attendeeId}/avatar-upload`,
                {
                    params: {
                        ContentType: fileType
                    },
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                }
            );
        } catch (error) {
            throw new Error("Could not get Avatar put url!");
        }

        try {
            await axios.request({
                url: putUrlResponse.data,
                data: options.image,
                method: "put",
                headers: {
                    "Content-Type": fileType
                }
            });
        } catch (error) {
            throw new Error("Avatar upload failed.");
        }
    }
}
