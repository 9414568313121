

























import { Component, Vue, Prop } from "vue-property-decorator";
import { AttendeeDataObject } from "@/types/interfaces";
import AttendeeCard from "@/components/shared/AttendeeCard.vue";

@Component({
    components: {
        AttendeeCard
    }
})
export default class AttendeesTaxonomySwimlane extends Vue {
    @Prop({ default: "Featured Attendees", required: false })
    headerLabel?: string;

    @Prop({ default: [], required: true })
    attendees?: Array<AttendeeDataObject>;

    @Prop({ default: false })
    isSearchButtonActive?: boolean;

    @Prop({ default: "" })
    headerClasses?: string;

    get pageOption() {
        return this.$store.getters.getPageOptions("attendees");
    }
}
