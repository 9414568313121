
























































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import svgCalendar from "@/components/svg/svg-mini-calendar.vue";
import svgMessage from "@/components/svg/svg-message.vue";
import { AttendeeDataObject, AttendeeSearchResult } from "@/types/interfaces";

@Component({
    components: {
        svgCalendar,
        svgMessage
    },
    computed: {
        hasAttendeeProfilePage() {
            return this.$store.getters.layoutOptions.hasAttendeeProfilePage;
        },
        usesCreateActivityControl() {
            const optionChecker = this.$store.getters.isPageOptionActiveInEnv;
            return optionChecker("createActivity", "isActive");
        }
    }
})
export default class AttendeeCard extends Vue {
    @Prop({
        default: function() {
            return {};
        },
        required: true
    })
    data!: AttendeeDataObject | AttendeeSearchResult;

    @Prop({ default: false })
    isMeetingRequest!: boolean;

    @Prop({ default: "" })
    message!: string;

    @Prop({ default: "column" })
    layout!: string;

    /**
     * Computed
     */
    get pageOptions() {
        return this.$store.getters.getPageOptions("attendees");
    }

    get showTitle(): boolean {
        // We want this to default to true, so check if the key exists
        return "showAttendeeTitles" in this.pageOptions
            ? this.pageOptions.showAttendeeTitles
            : true;
    }

    get addCityStateToOrgName() {
        return this.pageOptions.addCityStateToOrgName
            ? this.pageOptions.addCityStateToOrgName
            : false;
    }

    get shouldShowCountry() {
        if (!this.data.country) {
            return false;
        }

        if (!this.pageOptions.hideCountryIfUS) {
            return true;
        }

        return this.data.country !== "United States";
    }
    get activityPageOptions() {
        const returnValue = this.$store.getters.getPageOptions(
            "createActivity"
        );

        return returnValue;
    }

    get buttonPlaceholder() {
        return this.activityPageOptions.textHeader
            ? this.activityPageOptions.textHeader
            : "Request a Meeting";
    }
    /**
     * Methods
     */
    myImage(img: string) {
        return img ? img : require("../../assets/avatar2.png");
    }
    openMessage() {
        const attendeeId: string = this.data.attendeeId
            ? this.data.attendeeId
            : "";
        const fullName = this.getDisplayName();
        const company: string = this.data.companyName
            ? this.data.companyName
            : "";

        this.$router.push({
            name: "Message-Center",
            params: {
                id: attendeeId,
                name: fullName,
                companyName: company
            }
        });
    }

    requestMeeting(attendee: AttendeeDataObject | AttendeeSearchResult) {
        const attendeeData = JSON.stringify(attendee);

        this.$router.push({
            name: "CreateActivity",
            params: {
                attendee: attendeeData
            }
        });
    }

    getModifiedOrgName(attendee: AttendeeDataObject | AttendeeSearchResult) {
        return this.addCityStateToOrgName
            ? [attendee.companyName, attendee.city, attendee.state]
                  .filter((item) => item && item != "")
                  .join(", ")
            : attendee.companyName;
    }

    getDisplayName() {
        let parts = [];

        if (this.pageOptions.useMiddleInitial) {
            parts = [this.data.firstName, this.data.MI, this.data.lastName];
        } else {
            parts = [this.data.firstName, this.data.lastName];
        }

        if (this.pageOptions.usePrefixAndSuffix) {
            parts.unshift(this.data.prefix);
        }

        let name = parts
            .filter((part: string | undefined) => {
                return part && part.trim() != "";
            })
            .join(" ");

        if (
            this.pageOptions.usePrefixAndSuffix &&
            this.data.suffix &&
            this.data.suffix.trim() != ""
        ) {
            name += `, ${this.data.suffix}`;
        }

        return name;
    }

    logoColor() {
        const hue =
            "hsl(" +
            360 * Math.random() +
            "," +
            (25 + 70 * Math.random()) +
            "%," +
            (85 + 10 * Math.random()) +
            "%)";
        return { background: `${hue}` };
    }
}
