<template>
    <div>
        <PageTitleHeader :pageAccessor="'attendeeHeader'" class="py-4">
            <h1
                class="mb-0 title mg-title-header text-center mg-font-bold uppercase"
            >
                {{ textHeader ? textHeader : "ATTENDEE SEARCH" }}
            </h1>
        </PageTitleHeader>

        <section class="container flex flex-col items-center">
            <!-- TODO figure out how to do it -->
            <div v-if="externalAttendeeList" class="w-full pt-12 md:pl-40">
                <a
                    class="text-xl font-semibold"
                    :href="externalAttendeeList"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Click here to view a sortable list of attendees.</a
                >
                <p>
                    Use the search field below to find a specific individual.
                    From the search results (or their profile page) click "Send
                    a Message" to communicate with an attendee.
                </p>
            </div>

            <div class="pt-6 px-4 w-full max-w-lg flex flex-col lg:flex-row">
                <attendee-search-form
                    class="flex flex-col my-4 lg:w-4/6"
                    :profileOwnerId="myOwnProfileId"
                ></attendee-search-form>

                <div
                    class="flex flex-col lg:ml-8 mt-4 lg:w-2/6 xl:pl-16 font-bold"
                >
                    <div class="field">
                        <div
                            aria-hidden="true"
                            class="hidden lg:block invisible label"
                        >
                            &#8203;
                        </div>
                        <b-button
                            tag="router-link"
                            :to="
                                `/attendees/attendee-profile/${myOwnProfileId}`
                            "
                            class="button pill-button is-primary w-full justify-center"
                            icon-left="user-circle"
                        >
                            View My Profile
                        </b-button>
                    </div>

                    <div
                        aria-hidden="true"
                        class="hidden lg:block invisible label"
                    >
                        &#8203;
                    </div>
                    <b-button
                        v-if="showFullList"
                        tag="router-link"
                        to="/attendees/list"
                        class="button pill-button w-full mb-8 justify-center"
                        icon-left="users"
                        >{{ fullListButtonText }}</b-button
                    >
                    <b-button
                        v-if="usesCreateActivityControl"
                        class="button pill-button w-full mb-8 justify-center"
                        icon-left="calendar"
                        tag="router-link"
                        to="/request-meeting"
                        >{{ buttonPlaceholder }}
                    </b-button>
                </div>
            </div>
            <!-- <div v-else>
                <Spinners />
            </div> -->
        </section>

        <AdSlot slot-name="attendees" />

        <div class="container md:px-4">
            <hr />
        </div>

        <section class="container px-4 my-8">
            <Spinners v-if="featuredLoading" />
            <div>
                <AttendeeSwimLane
                    v-for="swimlane in swimlanesWithData"
                    :key="swimlane.key"
                    :header-label="swimlane.headerLabel"
                    :header-centered="swimlane.headerCentered"
                    :attendees="swimlane.data"
                />
            </div>
        </section>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import layoutImagesVuexModule from "@/store/vuex-modules/layoutImages";
import featuredAttendeeVuexModule from "@/store/vuex-modules/getFeaturedAttendees";
import attendeeVuexModule from "@/store/vuex-modules/attendees";

import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import AttendeeSearchForm from "@/components/attendee-search/AttendeeSearchForm.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import AttendeeSwimLane from "@/components/attendees/swimlanes/AttendeesTaxonomySwimlane.vue";
import AdSlot from "@/components/shared/AdSlot.vue";

const featuredAttendeeStore = getModule(featuredAttendeeVuexModule);
const layoutImagesStore = getModule(layoutImagesVuexModule);
const attendeeStore = getModule(attendeeVuexModule);

@Component({
    computed: {
        ...mapGetters(["awsConfig"])
    },
    components: {
        AttendeeSearchForm,
        PageTitleHeader,
        Spinners,
        AttendeeSwimLane,
        AdSlot
    }
})
export default class AttendeesTaxonomySampling extends Vue {
    isLoading = true;
    isOwnDataLoaded = false;
    id = "";

    /**
     * Computed
     */
    get pageOptions() {
        return this.$store.getters.getPageOptions("attendees");
    }

    get externalAttendeeList() {
        return this.pageOptions.externalAttendeeList;
    }

    get getLayoutOptions() {
        return this.$store.getters.layoutOptions;
    }

    get myOwnProfileId() {
        return this.$store.getters.user.id;
    }

    get layoutImagePagePrimary() {
        return layoutImagesStore.getLayoutImage("pagePrimary");
    }

    get attendeeApiOptions() {
        this.id = this.myOwnProfileId;
        const options = { id: this.id, isDemoData: false };
        return options;
    }

    get ownProfileInformation() {
        return attendeeStore.attendee;
    }

    get textHeader() {
        return this.pageOptions.textHeader;
    }

    get showFullList() {
        return this.pageOptions.section.fullAttendeeList;
    }

    get fullListButtonText() {
        return this.pageOptions.content?.attendeeListLabel
            ? this.pageOptions.content.attendeeListLabel
            : "View Full List";
    }

    get attendeeSwimlanes() {
        return this.pageOptions.swimLanes ? this.pageOptions.swimLanes : [];
    }

    get swimlanesWithData() {
        return featuredAttendeeStore.featuredAttendeeSwimlanes;
    }

    get featuredLoading() {
        return !featuredAttendeeStore.featuredAttendeesLoaded;
    }
    get createActivityOptions() {
        const returnValue = this.$store.getters.getPageOptions(
            "createActivity"
        );

        return returnValue;
    }
    get usesCreateActivityControl() {
        const optionChecker = this.$store.getters.isPageOptionActiveInEnv;
        return optionChecker("createActivity", "isActive");
    }

    get buttonPlaceholder() {
        return this.createActivityOptions.textHeader
            ? this.createActivityOptions.textHeader
            : "Request a Meeting";
    }

    /**
     * Lifecycle
     */
    created() {
        featuredAttendeeStore.getFeaturedAttendees(this.attendeeSwimlanes);
    }

    /**
     * Methods
     */
}
</script>
