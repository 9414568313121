
























import { Vue, Component, Prop } from "vue-property-decorator";

interface BannerAd {
    slots: Array<string>;
    image: string;
    url: string;
    title?: string;
    altText?: string;
}

const blankAd = {
    slots: [],
    image: "",
    url: ""
};

@Component({})
export default class AdSlot extends Vue {
    @Prop({
        default: "",
        required: true
    })
    slotName!: string;

    currentAd: BannerAd = blankAd;

    get bannerAds() {
        return this.$store.getters.bannerAds;
    }

    get validAds() {
        return this.bannerAds && this.bannerAds.length
            ? this.bannerAds.filter((ad: BannerAd) => {
                  return (
                      ad.slots.includes("all") ||
                      ad.slots.includes(this.slotName)
                  );
              })
            : [];
    }

    mounted() {
        this.selectRandomAd();
    }

    selectRandomAd() {
        if (this.validAds.length) {
            const rand = Math.floor(
                Math.random() * Math.floor(this.validAds.length)
            );
            this.currentAd = this.validAds[rand];
        } else {
            this.currentAd = blankAd;
        }
    }

    adAltText(ad: BannerAd) {
        return ad.altText && ad.altText !== "" ? ad.altText : "Ad Banner";
    }

    adTitle(ad: BannerAd) {
        return ad.title && ad.title !== "" ? ad.title : "Advertisement";
    }
}
